/**
 * Retrieves value from URL for `name`.
 * @param {string} name
 * @returns Value or `null`
 */
import { omit } from 'ramda';
import { matchPath } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { getPropertyConfigsAndDetails } from '../services/propertyService';

export const getUrlParam = (name) => {
  const urlParams = new URLSearchParams(document.location.search.substring(1));
  return urlParams.get(name);
};

export const buildIntentURL = ({
  url,
  state = {},
  type, // 'assignUnit' | 'tellUs',
}) => {
  const params = new URLSearchParams({
    state: JSON.stringify({ ...state, type }),
  });
  return new URL(`${url}/intent/?${params}`);
};
/**
 * Retrieves HEX Color from URL for `name`.
 * The `#` should be excluded.  For example, `#FF0000` would be `FF0000`.
 * @param {string} name
 * @returns HEX Color or `null`
 */
export const getUrlColor = (name) => {
  const colorParam = getUrlParam(name);
  return colorParam ? `#${colorParam}` : null;
};

/**
 * Uses browser built-in localization library to format number.
 * @param {string|number} number
 * @returns string
 */
export const formatNumber = (number) =>
  Intl.NumberFormat('en-US').format(Math.round(number));

export const getPropertyDetails = async () => {
  const { pathname } = window.location;

  const match = matchPath(pathname, {
    path: '/unit-availability/:organizationId/:propertyId',
    exact: true,
    strict: false,
  });

  const propertyDetails = {
    organizationId: null,
    propertyId: null,
    propertyType: null,
    configs: null,
  };

  if (match) {
    propertyDetails.organizationId = match?.params?.organizationId ?? '';
    propertyDetails.propertyId = match?.params?.propertyId ?? '';

    if (propertyDetails.organizationId && propertyDetails.propertyId) {
      const response = await getPropertyConfigsAndDetails({
        organizationId: propertyDetails.organizationId,
        propertyId: propertyDetails.propertyId,
      });
      propertyDetails.propertyType = response?.propertyType;
      propertyDetails.configs = omit(['propertyType'], response ?? {});

      // Initialize GA tracking if the property has been set up
      const trackingId =
        propertyDetails.configs?.googleAnalyticsTracking?.trackingId;
      if (trackingId && !ReactGA.isInitialized) {
        ReactGA.initialize([{ trackingId }]);
      }
    }
  }
  return propertyDetails;
};
